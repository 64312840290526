<template>
  <transition name="fade">
    <FullScreenModal class="collect-from-closet" v-if="modalShow" title="選取單品" @back="closeCollectFromClosetModal">
      <h4 class="fs-20px">從衣櫥中挑選單品</h4>
      <!-- <b-button variant="link">
        <span>單品不在衣櫥中嗎？ 新增單品</span>
      </b-button> -->
      <ClothCategoryTabs  @set="setCategoryFilter" />
      <!-- <div class="option-nav d-flex justify-content-between align-items-center">
        <div class="fs-14px">共34 件</div>
        <b-button variant="link">
          <Icon name="Filter_big" size="24" />
        </b-button>
      </div> -->
      <div class="closet-list">
        <div class="closet-item" :class="{'selected': isSelected(item.serial)}" v-for="(item, index) in filteredCloths" :key="index" @click="toggleSelectedItem(item)">
          <img :src="item.imgs[0]" alt="closet-item-image">
          <Icon class="lock" v-if="!item.isPublic" name="lock" size="16" />
          <div class="check-icon">
            <Icon name="check-bold" size="11" />
          </div>
        </div>
      </div>
      <CustomModalFooter class="collect-footer d-flex justify-content-between align-items-center flex-nowrap">
        <div class="info">
          <div class="fs-16px">
            <span>已選取 </span>
            <span v-text="selectedList.length"></span>
            <span>/</span>
            <span v-text="collectLimit"></span>
            <span> 件</span>
          </div>
        </div>
        <b-button variant="primary" @click="updateSelectedList">
          <span>完成</span>
        </b-button>
      </CustomModalFooter>
    </FullScreenModal>
  </transition>
</template>

<script>
import commonBusyAndErrorHandling from "@/assets/js/commonBusyAndErrorHandling";
import {mapState} from 'vuex';
import FullScreenModal from "@/components/FullScreenModal.vue";
import ClothCategoryTabs from "@/components/clothList/ClothCategoryTabs.vue";
import CustomModalFooter from "@/components/CustomModalFooter.vue";

export default {
  mixins: [commonBusyAndErrorHandling],
  name: 'CollectFromCloset',
  data() {
    return {
      readingBusyName: 'COLLECT_FROM_CLOSET',

      init: false,
      selectedList: [],
      tabCategory: -1,
    };
  },
  props: {
    value: {
      type: Boolean,
    },
    collectLimit: {
      type: Number,
      required: true,
    },
    collectedList: {
      type: Array,
    }
  },
  components: {
    FullScreenModal,
    ClothCategoryTabs,
    CustomModalFooter,
  },
  computed: {
    ...mapState(['clothCategories']),

    modalShow: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      }
    },
    hasCollectedList() {
      return this.collectedList && this.collectedList.length > 0
    },
    cloths() {
      const cs = this.$store.getters.clothWithWearCount;
      const list = cs.filter((c) => {
        return !c.isDeleted && c.recycleType === -1;
      });
      return list;
    },
    filters() {
      const filter = [];
      if (this.tabCategory !== -1) {
        filter.push({
          type: 'category1',
          value: this.tabCategory
        });
      }
      // if (this.filterTags.length > 0) {
      //   filter.push({
      //     type: 'tags',
      //     value: this.filterTags
      //   });
      // }
      // if (this.filterColors.length > 0) {
      //   filter.push({
      //     type: 'color',
      //     value: this.filterColors,
      //   });
      // }
      // if (this.filterPublic !== -1) {
      //   filter.push({
      //     type: 'isPublic',
      //     value: this.filterPublic === 1,
      //   });
      // }
      return filter;
    },
    filteredCloths() {
      let fs = [... this.cloths];
      fs = fs.filter((c) => {
        for (const f of this.filters) {
          if (f.type === 'tags') {
            let found = false;
            for (const tag of c.brands) {
              if (f.value.indexOf(tag) !== -1) {
                found = true;
                break;
              }
            }

            if (!found) {
              for (const tag of c.tags) {
                if (tag.indexOf(f.value) !== -1) {
                  found = true;
                  break;
                }
              }
              const c1Name = this.__queryCategoryName(c.category1);
              const c2Name = this.__queryCategoryName(c.category2);
              if (c1Name.indexOf(f.value) !== -1 || c2Name.indexOf(f.value) !== -1 ) {
                found = true;
              }
              // console.log(`${c1Name}, ${c2Name}, ${found}`);
            }
            if (!found) {
              return false;
            }
          } else if (Array.isArray(c[f.type])) {
            if (Array.isArray(f.value)) {
              let found = false;
              for (const tag of c[f.type]) {
                if (f.value.indexOf(tag) !== -1) {
                  found = true;
                  break;
                }
              }
              if (!found) {
                return false;
              }
            } else {
              if (c[f.type].indexOf(f.value) === -1) {
                return false;
              }
            }
          } else {
            if (Array.isArray(f.value)) {
              if (f.value.indexOf(c[f.type]) === -1) {
                return false;
              }
            } else {
              if (c[f.type] !== f.value) {
                return false;
              }
            }
          }
        }

        return true;
      });
      return fs;
    },
  },
  watch: {
  },
  async mounted() {
    if(this.hasCollectedList) {
      this.collectedList.forEach(serial => {
        const cloth = this.cloths.find(cloth => cloth.serial === serial);
        if(cloth) {
          this.selectedList.push(cloth);
        }
      })
    }
  },
  methods: {
    closeCollectFromClosetModal() {
      this.modalShow = false;
    },
    setCategoryFilter(category) {
      this.tabCategory = category.value;
    },
    isSelected(serial) {
      const index = this.selectedList.findIndex(selectedItem => selectedItem.serial === serial);
      return index !== -1;
    },
    toggleSelectedItem(item) {
      const index = this.selectedList.findIndex(selectedItem => selectedItem.serial === item.serial);
      if (index !== -1) {
        this.selectedList.splice(index, 1);
      } else {
        this.selectedList.push(item);
      }
    },
    async updateSelectedList() {
      const selectedSerials = this.selectedList.map(cloth => cloth.serial);
      await this.$emit('uploadCollectedItem', this.hasCollectedList ? selectedSerials.filter(serial => !this.collectedList.includes(serial)) : selectedSerials);
      this.modalShow = false;
    },
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/basic";
.collect-from-closet {
  .h4.fs-20px {
    margin-bottom: .125rem;
  }
  .btn-link {
    padding: 0;
  }
  .option-nav {
    .btn-link {
      padding: 10px;
    }
  }
  .closet-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0.5rem;
    padding: .5rem 0 1.5rem;
  }
  .closet-item {
    cursor: pointer;
    position: relative;
    background: $color-image-background;
    width: 100%;
    padding-top: 100%;
    border-radius: .5rem;
    overflow: hidden;
    filter: brightness(.8);
    &.selected {
      filter: brightness(1);
      .check-icon {
        background: $color-status-success;
      }
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .lock {
      position: absolute;
      top: .25rem;
      right: .125rem;
    }
    .check-icon {
      position: absolute;
      bottom: .25rem;
      right: .25rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background: $color-input-grey;
      width: 1rem;
      height: 1rem;
      border-radius: 100%;
      svg {
        color: $typography-primary-inverted;
      }
    }
  }
}
</style>
<style lang="scss">
@import "src/assets/scss/basic";
.collect-from-closet {
  .box-body {
    padding: 1rem 1rem 5rem;
  }
  .cloth-category-tabs {
    margin-left: -.25rem;
    margin-right: -1rem;
    .category-tab {
      font-size: .875rem;
      padding: .125rem .5rem;
      margin: .5rem .5rem;
    }
  }
  .collect-footer {
    gap: 1rem;
    .btn.btn-primary {
      width: 10rem;
    }
  }
}
</style>
  
<template>
  <div class="custom-input-group">
    <label>
      <span class="label" :class="{'empty-input': inputValue === ''}" v-text="labelText"></span>
      <input :type="inputType" v-model="inputValue">
    </label>
    <div class="form-desc" v-if="descText !== ''" v-text="descText"></div>
    <div class="form-desc warning" v-if="errorText !== ''" v-text="errorText"></div>
  </div>
</template>

<script>
export default {
  name: 'CustomInputGroup',
  data() {
    return {
    }
  },
  components: {
  },
  props: {
    value: {
      type: String,
      required: true,
      default: '',
    },
    inputType: {
      type: String,
      default: 'text',
    },
    labelText: {
      type: String,
      default: 'label',
    },
    descText: {
      type: String,
      default: '',
    },
    errorText: {
      type: String,
      default: '',
    },
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  methods: {
  }

}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/basic";
.custom-input-group {
  margin-bottom: 1rem;
  label {
    position: relative;
    width: 100%;
    border-radius: .5rem;
    border: 1px solid $color-border;
    padding: 2.125rem 1rem .75rem;
    margin-bottom: 0;
    &:focus-within {
      .label.empty-input {
        top: .6rem;
        color: $typography-secondary-default;
        font-size: $font-small;
      }
    }
    .label {
      position: absolute;
      top: .6rem;
      color: $typography-secondary-default;
      font-size: $font-small;
      line-height: 1.5;
      transition: .2s;
      &.empty-input {
        top: calc(50% - 12px);
        left: 1rem;
        color: $typography-primary-default;
        font-size: $font-medium;
        padding: 0;
      }
    }
    input {
      width: 100%;
      font-size: $font-medium;
      line-height: 1.5;
      border: none;
      &:focus-visible {
        outline: none;
      }
      &[type="text"], &[type="tel"], &[type="email"] {
        padding: 0;
      }
    }
  }
  .form-desc {
    color: $typography-secondary-default;
    font-size: $font-small;
    line-height: 1.5;
    padding-left: 1rem;
    margin-top: .125rem;
    &.warning {
      color: $typography-primary-error;
    }
  }
}
</style>
<template>
  <div class="single-item-clothes-card">
    <div class="card-image">
      <ThumbnailSlider :img-src-array="cardData.imgs" :img-alt="'product-image'" />
    </div>
    <div class="card-info d-flex flex-column justify-content-between">
      <div>
        <div class="brand-name fs-12px fw-bold" :class="{'unfilled': cardData.brand === ''}" v-text="formattedBrandName"></div>
        <div class="item-name fs-14px" :class="{'unfilled': !Boolean(category1 && category2)}" v-text="formattedCardName"></div>
        <div class="item-info fs-14px d-flex align-items-center" :class="{'unfilled': priceAndSizeUnfilled}">
          <span>購入價 $</span>
          <span v-text="cardData.boughtPrice"></span>
        </div>
        <div class="item-info fs-14px d-flex align-items-center" :class="{'unfilled': priceAndSizeUnfilled}">
          <span>尺寸&nbsp;</span>
          <span>{{ cardData.size ? cardData.size : '未填' }}</span>
        </div>
        <div class="desc fs-14px" v-text="condition"></div>
      </div>
      <b-button v-if="!hideEditBtn" class="fill-info-btn fw-bold" variant="link" @click="editInfo">
        <span>填寫完整資訊</span>
        <Icon name="chevron-right" size="24" />
      </b-button>
    </div>
    <b-button v-if="!hideRemoveBtn" class="delete-btn" variant="link" @click="removeItem">
      <Icon name="delete" size="20" />
    </b-button>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import ThumbnailSlider from "@/components/ThumbnailSlider.vue";

export default {
  name: 'SingleItemClothesCard',
  data() {
    return {
      readingBusyName: 'SingleItemClothesCard',

      init: false,
    };
  },
  props: {
    cardData: {
      type: Object,
      require: true,
    },
    hideEditBtn: {
      type: Boolean,
      default: false,
    },
    hideRemoveBtn: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    ThumbnailSlider,
  },
  computed: {
    ...mapState(['clothCategories']),

    category1() {
      const res = this.clothCategories.filter((item) => {
        return item.serial === this.cardData.category1;
      });
      if (res.length > 0) {
        return res[0];
      }
      return null;
    },
    category2() {
      for (const c1 of this.clothCategories) {
        for (const c2 of c1.sub) {
          if (c2.serial === this.cardData.category2) {
            return c2;
          }
        }
      }
      return null;
    },
    formattedCardName() {
      return this.category1 && this.category2 ? this.category1.name + ' / ' + this.category2.name : '單品類別'
    },
    formattedBrandName() {
      return this.cardData.brand ? this.cardData.brand : '品牌名';
    },
    priceAndSizeUnfilled() {
      return this.cardData.price === 0 || this.cardData.price === null || this.cardData.size === null || this.cardData.size === '';
    },
    condition() {
      return this.cardData.erosionRate ? this.cardData.erosionRate : '新舊程度'
    },
  },
  watch: {
  },
  async mounted() {
  },
  methods: {
    ...mapActions(['showMsgModal', 'appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg', 'setAddFriendShow']),
    editInfo() {
      this.$emit('edit');
    },
    removeItem() {
      this.$emit('remove');
    },
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/basic";
.single-item-clothes-card {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
  gap: 1rem;
  padding: 1rem;
  .card-image {
    width: 120px;
    height: 120px;
  }
  .brand-name {
    color: $typography-primary-default;
    &.unfilled {
      color: $typography-secondary-default;
    }
  }
  .item-name, .item-info {
    color: $typography-primary-default;
    margin-bottom: .125rem;
    &.unfilled {
      color: $typography-secondary-default;
    }
  }
  .item-info {
    .divide {
      background: $color-border;
      width: 1px;
      height: .5rem;
      margin: 0 .5rem;
    }
  }
  .desc {
    color: $typography-secondary-default;
  }
  .fill-info-btn {
    display: flex;
    align-items: center;
    padding: 0;
  }
  .delete-btn {
    position: absolute;
    top: 0;
    right: 0;
    padding: 12px;
  }
}
</style>
<style lang="scss">
@import "src/assets/scss/basic";
.single-item-clothes-card {
  .card-image {
    .thumbnail-slider {
      background: $color-btn-disabled-background-primary;
      padding: 1.25rem;
      .slider-dots {
        bottom: .5rem;
        .dot {
          width: 4px;
          height: 4px;
          &:not(:first-child) {
            margin-left: 4.5px;
          }
        }
      }
    }
  }
}
</style>
  
<template>
  <div class="order-sell-cloth-item">
    <div class="flex-content">
      <div class="item-image">
        <ThumbnailSlider :img-src-array="sellCloth.imgs" :img-alt="'product-image'" />
      </div>
      
      <div class="item-info">
        <div class="d-flex justify-content-between mb-3">
          <div class="name-with-brand">
            <p class="mb-0 text-secondary"><small>{{ sellCloth.brand }}</small></p>
            <p class="mb-0">{{ category1.name }}/{{ category2.name }}</p>
          </div>

          <div class="">
            <span class="status-b status-stock" v-if="sellCloth.stockStatus === 0">已收件</span>
            <span class="status-b status-sold" v-if="sellCloth.stockStatus === 1">售出</span>
            <span class="status-b status-cancel" v-if="sellCloth.stockStatus === 2 || sellCloth.stockStatus === 4">捐贈/退回</span>
          </div>
        </div>

        <div class="price-info">
          <div class="d-flex justify-content-between">
            <div>
              <p class="mb-0">購入價</p>
            </div>
            <div>
              <p class="mb-0">{{ sellCloth.boughtPrice }}</p>
            </div>
          </div>
          
          <div v-if="sellCloth.stockStatus === 0">
            <div class="d-flex justify-content-between" v-if="eventObj.priceDisplayType === 0">
              <div>
                <p class="mb-0">預期回饋金</p>
              </div>
              <div>
                <p class="mb-0">${{ parseInt(sellCloth.predictPriceMin * sellCloth.returnRatePercentage / 100) }} - ${{ parseInt(sellCloth.predictPriceMax * sellCloth.returnRatePercentage / 100) }}</p>
              </div>
            </div>
  
            <div class="d-flex justify-content-between" v-if="eventObj.priceDisplayType === 1">
              <div>
                <p class="mb-0">預期售價</p>
              </div>
              <div>
                <p class="mb-0">${{ sellCloth.predictPriceMin }} - ${{ sellCloth.predictPriceMax }}</p>
              </div>
            </div>

          </div>

          <div v-if="sellCloth.stockStatus === 1">
            <div class="d-flex justify-content-between">
              <div>
                <p class="mb-0">回饋金</p>
              </div>
              <div>
                <p class="mb-0">${{ sellCloth.soldReturn }}</p>
              </div>
            </div>

          </div>

        </div>

      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import ThumbnailSlider from "@/components/ThumbnailSlider.vue";

export default {
  name: 'OrderSellClothItem',
  data() {
    return {
    };
  },
  props: {
    sellCloth: {
      type: Object,
      require: true,
    },
    eventObj: {
      type: Object,
      require: true,
    },
  },
  components: {
    ThumbnailSlider,
  },
  computed: {
    ...mapState(['clothCategories']),

    category1() {
      const res = this.clothCategories.filter((item) => {
        return item.serial === this.sellCloth.category1;
      });
      if (res.length > 0) {
        return res[0];
      }
      return null;
    },
    category2() {
      for (const c1 of this.clothCategories) {
        for (const c2 of c1.sub) {
          if (c2.serial === this.sellCloth.category2) {
            return c2;
          }
        }
      }
      return null;
    },
  },
  watch: {
  },
  async mounted() {
  },
  methods: {
    
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/basic";
.order-sell-cloth-item {
  padding: 1rem;
  .flex-content {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: 1rem;
    .item-image {
      flex-shrink: 0;
      width: 90px;
      height: 90px;
    }

    .item-info {
      flex: 1 1;
      font-size: .8rem;

      .status-b {
        border: solid 1px #E7B780;
        padding: 4px 12px;
        border-radius: .5rem;
        font-size: 1rem;
        &.status-stock {
          
        }
        &.status-sold {
          background-color: #E7B780;
          font-weight: 600;
        }
        &.status-cancel {
          border: none;
          background-color: #F7F7F7;
          color: #B6B6B6;
        }
      }

      .price-info {
        font-size: .8rem;
      }
    }
  }
}
</style>
<style lang="scss">
@import "src/assets/scss/basic";
.order-sell-cloth-item {
  .item-image {
    .thumbnail-slider {
      background: $color-btn-disabled-background-primary;
      padding: 1.25rem;
      .slider-dots {
        bottom: .5rem;
        .dot {
          width: 4px;
          height: 4px;
          &:not(:first-child) {
            margin-left: 4.5px;
          }
        }
      }
    }
  }
}
</style>
  
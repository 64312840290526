<template>
  <div class="history-item">
    <div class="content">
      <div class="history-image">
        <img :src="itemData.event.displayImg" alt="service image">
      </div>
      <div class="history-info d-flex flex-column justify-content-between">
        <h3 class="name fs-h3" v-text="formattedDisplayName"></h3>
        <div class="desc fs-14px" v-text="itemData.businessAccount.name"></div>
        <div class="price fs-16px fw-bold" v-text="formattedPrice"></div>
      </div>
    </div>

    <div class="history-item-images-group d-flex align-items-center" v-if="itemData.clothImgs.length > 0">
      <div class="image-item" v-for="(item, index) in itemData.clothImgs" :key="index">
        <img :src="item" alt="product image">
      </div>
    </div>

    <div class="d-flex justify-content-between align-items-center">
      <div class="service-state fs-12px fw-bold" v-text="statusName"></div>
      <div class="end-time fs-14px" v-if="itemData.status < 3">額滿截止</div>
      <div class="end-time fs-14px" v-if="itemData.status === 3 && itemData.bargainCount === 0">
        <span v-text="formattedWaitQuoteDate"></span>
        <span> 23:59 前</span>
      </div>
      <div class="end-time fs-14px" v-if="itemData.status === 3 && itemData.bargainCount > 0">
        <span v-text="formattedBargainDate"></span>
        <span> 23:59 前</span>
      </div>
      <div class="end-time fs-14px" v-if="itemData.status === 4">
        <span v-text="formattedWaitConfirmPriceDate"></span>
        <span> 23:59 前</span>
      </div>
      <div class="end-time fs-14px" v-if="itemData.status === 5">
        <span v-text="formattedConfirmPriceDate"></span>
        <span> 23:59 前</span>
      </div>
      <div class="end-time fs-14px" v-if="itemData.status === 6">
        <span v-text="formattedConfirmPriceDate"></span>
        <span> 23:59 前截止</span>
      </div>
    </div>

    <b-button class="mt-4" block variant="primary" v-if="itemData.status === 1" @click="editClothingDataFormClicked">
      <span>填寫衣物資訊</span>
    </b-button>
    <b-button class="mt-4" block variant="primary" v-if="itemData.status === 0">
      <span>立即付款</span>
    </b-button>
    <b-button class="mt-4" block variant="primary" v-if="itemData.status === 4" @click="priceCheckClicked">
      <span>確認報價</span>
    </b-button>
    <b-button class="mt-4" block variant="primary" v-if="itemData.status === 5" @click="transferCheckClicked">
      <span>寄出回報</span>
    </b-button>
    <b-button class="mt-4" block variant="primary" v-if="itemData.status === 7" @click="viewSellClothClicked">
      <span>查看寄售狀態</span>
    </b-button>

    <div class="more-option">
      <b-dropdown variant="link" toggle-class="more-option-btn" right no-caret>
        <template #button-content>
          <Icon name="More" size="24" />
        </template>
        <b-dropdown-item-button @click="viewOrderStatusClicked">訂單進度</b-dropdown-item-button>
        <b-dropdown-item-button @click="viewOrderDetailClicked">訂單資訊</b-dropdown-item-button>
        <b-dropdown-item-button v-if="itemData.status <= 2" @click="deleteOrderClicked(itemData)" class="delete">放棄寄售</b-dropdown-item-button>
      </b-dropdown>
    </div>

    <SemiOverlayModal v-model="showViewOrderStatus">
      <HistoryOrderStatus :status-data="itemData" @back="closeViewOrderStatusModal" />
    </SemiOverlayModal>
    <transition name="fade">
      <FullScreenModal v-if="showViewOrderDetail" title="詳細資訊" btn-type="close" @back="closeViewOrderDetailModal">
        <HistoryOrderDetail :detail-data="itemData" />
      </FullScreenModal>
    </transition>
    <transition name="fade">
      <FullScreenModal v-if="showClothingDataForm" title="填寫衣物資料" @back="closeClothingDataFormModal">
        <ClothingDataForm :form-data="itemData" @close="closeClothingDataFormModal" />
      </FullScreenModal>
    </transition>
    <transition name="fade">
      <FullScreenModal v-if="showOrderPriceCheck" title="寄售資訊" btn-type="close" @back="closeOrderPriceCheckModal">
        <HistoryOrderPriceCheck :order-data="itemData" @close="closeOrderPriceCheckModal" />
      </FullScreenModal>
    </transition>
    <transition name="fade">
      <FullScreenModal v-if="showOrderTransferCheck" title="寄出寄售單品" btn-type="close" @back="closeOrderTransferCheckModal">
        <HistoryOrderTransferCheck :order-data="itemData" @close="closeOrderTransferCheckModal" />
      </FullScreenModal>
    </transition>
    <transition name="fade">
      <FullScreenModal v-if="showOrderSellClothInfo" title="寄售資料" @back="closeOrderSellClothInfoModal">
        <OrderSellClothInfo :orderSerial="itemData.serial" :eventObj="itemData.event"/>
      </FullScreenModal>
    </transition>
  </div>
</template>

<script>
import moment from "moment";
import {mapActions, mapState} from 'vuex';
import SemiOverlayModal from "@/components/SemiOverlayModal.vue";
import HistoryOrderStatus from "@/views/share/HistoryOrderStatus.vue";
import FullScreenModal from "@/components/FullScreenModal.vue";
import HistoryOrderDetail from "@/views/share/HistoryOrderDetail.vue";
import ClothingDataForm from "@/views/share/ClothingDataForm.vue";
import HistoryOrderPriceCheck from "@/views/share/HistoryOrderPriceCheck.vue";
import HistoryOrderTransferCheck from "@/views/share/HistoryOrderTransferCheck.vue";
import OrderSellClothInfo from "@/views/share/OrderSellClothInfo.vue";

export default {
  name: 'HistoryItem',
  data() {
    return {
      readingBusyName: 'HistoryItem',
      savingBusyName: 'HistoryItemSave',

      init: false,
      eventData: null,
      showViewOrderStatus: false,
      showViewOrderDetail: false,
      showClothingDataForm: false,
      showOrderPriceCheck: false,
      showOrderTransferCheck: false,
      showOrderSellClothInfo: false,
    };
  },
  props: {
    itemData: {
      type: Object,
      required: true,
    },
  },
  components: {
    SemiOverlayModal,
    HistoryOrderStatus,
    FullScreenModal,
    HistoryOrderDetail,
    ClothingDataForm,
    HistoryOrderPriceCheck,
    HistoryOrderTransferCheck,
    OrderSellClothInfo,
  },
  computed: {
    ...mapState('event', ['scheduledSerial']),

    formattedDisplayName() {
      switch (this.itemData.event.type) {
        case 0:
          return '[寄售] ' + this.itemData.event.displayName;
        case 1:
          return '[捐贈] ' + this.itemData.event.displayName;
        default:
          return '資料載入錯誤';
      }
    },
    formattedPrice() {
      return '$' + this.itemData.event.reviewPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    statusName() {
      switch (this.itemData.status) {
        case 0:
          return '待付款';
        case 1:
          return '待回覆衣物資訊';
        // case 2:
          // return '等待付款';
        case 3:
          return '等待店家報價';
        case 4:
          return '確認店家報價';
        case 5:
          return '寄出衣物';
        case 6:
          return '等待店家收件';
        case 7:
          return '店家已收件';
        default:
          return '未知狀態';
      }
    },
    formattedWaitQuoteDate() {
      return this.itemData.waitQuoteDate ? moment(this.itemData.waitQuoteDate).add(7, 'days').format('MM/DD') : '';
    },
    formattedBargainDate() {
      return this.itemData.bargainDate ? moment(this.itemData.bargainDate).add(7, 'days').format('MM/DD') : '';
    },
    formattedWaitConfirmPriceDate() {
      return this.itemData.waitConfirmPriceDate ? moment(this.itemData.waitConfirmPriceDate).add(7, 'days').format('MM/DD') : '';
    },
    formattedConfirmPriceDate() {
      return this.itemData.confirmPriceDate ? moment(this.itemData.confirmPriceDate).add(7, 'days').format('MM/DD') : '';
    },
  },
  watch: {
  },
  async mounted() {
    if(this.itemData.serial === this.scheduledSerial) {
      this.editClothingDataFormClicked();
      await this.clearScheduledSerial()
    }
  },
  methods: {
    ...mapActions('event', ['clearScheduledSerial']),

    viewOrderStatusClicked() {
      this.showViewOrderStatus = true;
    },
    closeViewOrderStatusModal() {
      this.showViewOrderStatus = false;
    },
    viewOrderDetailClicked() {
      this.showViewOrderDetail = true;
    },
    deleteOrderClicked(order) {
      this.$bvModal.msgBoxConfirm('確定刪除此訂單嗎?', {
        okTitle: '確定刪除',
        okVariant: 'danger',
        centered: true,
        cancelTitle: '取消',
      })
        .then(value => {
          if (value) {
            this.$emit('delete', order);
          }
        })
        .catch(err => {
          // An error occurred
          console.error(err);
        })
    },
    closeViewOrderDetailModal() {
      this.showViewOrderDetail = false;
    },
    editClothingDataFormClicked() {
      this.showClothingDataForm = true;
    },
    closeClothingDataFormModal() {
      this.showClothingDataForm = false;
    },
    priceCheckClicked() {
      this.showOrderPriceCheck = true;
    },
    closeOrderPriceCheckModal() {
      this.showOrderPriceCheck = false;
    },
    transferCheckClicked() {
      this.showOrderTransferCheck = true;
    },
    closeOrderTransferCheckModal() {
      this.showOrderTransferCheck = false;
    },
    viewSellClothClicked() {
      this.showOrderSellClothInfo = true;
    },
    closeOrderSellClothInfoModal() {
      this.showOrderSellClothInfo = false;
    },
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/basic";
.history-item {
  position: relative;
  border: 1px solid $color-border;
  border-radius: 1rem;
  padding: 1rem;
  .content {
    display: flex;
    gap: 1rem;
    margin-bottom: .75rem;
  }
  .history-image {
    position: relative;
    width: 80px;
    height: 80px;
    border: 1px solid $color-border;
    border-radius: .75rem;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .name.fs-h3 {
    margin-bottom: 0;
  }
  .history-info {

  }
  .service-state {
    background: $color-pink;
    border-radius: .25rem;
    padding: .25rem .5rem;
  }
  .end-time {
    color: $typography-secondary-default;
  }
  .more-option {
    position: absolute;
    top: .5rem;
    right: .5rem;
  }
  .history-item-images-group {
    gap: .25rem;
    margin-bottom: 1rem;
    .image-item {
      background: $color-image-background;
      width: 56px;
      height: 56px;
      border-radius: 5.6px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}
</style>
<style lang="scss">
@import "src/assets/scss/basic";
.history-item {
  .more-option-btn.btn {
    padding: .5rem;
  }
  .dropdown-menu {
    min-width: 100px;
  }
  .more-option {
    .dropdown-item {
      width: 100px;
      text-align: center;
    }
  }
  .delete .dropdown-item {
    color: $typography-primary-error;
    &:active {
      color: $color-pink;
    }
  }
}
</style>
  
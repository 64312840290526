<template>
  <div class="order-sell-cloth-info">
    <div class="container py-3">
      <p>
        衣物上架後可能會依據販售時間或配合銷售活動優惠調整定價，本次寄售分潤比例為 {{ returnPercentageText }}，實際回饋金將以最終售出定價計算。
      </p>

      <h4 class="font-weight-bold">寄售單品：{{ list.length }}件</h4>

      <OrderSellClothItem v-for="(item) in list" :key="item.serial" :sellCloth="item" :eventObj="eventObj"></OrderSellClothItem>
    </div>
  </div>
</template>

<script>
// import moment from "moment";
// import { mapActions } from 'vuex';
import commonBusyAndErrorHandling from "@/assets/js/commonBusyAndErrorHandling";
import OrderSellClothItem from "@/views/share/OrderSellClothItem.vue"

export default {
  mixins: [commonBusyAndErrorHandling],
  name: 'OrderSellClothInfo',
  data() {
    return {
      readingBusyName: 'OrderSellClothInfo',
      list: [],
      init: false,
    };
  },
  props: {
    orderSerial: {
      type: Number,
      required: true,
    },
    eventObj: {
      type: Object,
      require: true,
    },
  },
  components: {
    OrderSellClothItem,
  },
  computed: {
    returnPercentageText() {
      if (this.list.length === 0) {
        return '';
      }
      const p = this.list[0].returnRatePercentage;
      for (const l of this.list) {
        if (l.returnRatePercentage !== p) {
          return 'Mixed';
        }
      }
      return `${p}%`;
    },
  },
  watch: {
  },
  async mounted() {
    await this.handleAsyncTask(this.readInfo);
  },
  methods: {

    async readInfo() {
      const list = await this.$store.dispatch('api/getOrderSellClothListPromise', this.orderSerial);
      list.map((d) => {
        this.list.push(d);
      });
    },
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/basic";
.order-sell-cloth-info {
  
}
</style>
  
<template>
  <div class="history-order-price-check">
    <div class="price-check-desc fs-16px">
      <span>在按下按鈕前，請確認以下內容無誤，送出後無法修改。</span>
    </div>
    <div class="price-check-info fs-16px" v-if="!isNeedBargain">
      <div class="agree-consign d-flex align-items-center">
        <b-form-checkbox v-model="agreeConsign">我同意</b-form-checkbox>
        <div class="agree-consign-link" @click="openConfirmAgreeConsignModal">委託約定</div>
      </div>
      <b-form-group>
        <template v-slot:label>
          <div class="fs-16px">
            <span>契約人</span>
          </div>
        </template>
        <b-form-input v-model="orderData.contactName" disabled></b-form-input>
      </b-form-group>
      <b-form-group>
        <template v-slot:label>
          <div class="label-name fs-16px">
            <span>契約人身分證字號</span>
          </div>
        </template>
        <b-form-input v-model="contractorID"></b-form-input>
      </b-form-group>
    </div>
    <div class="price-check-count fs-h3">
      <span>{{ isNeedBargain ? '同意與待議價共' : '同意 共' }}</span>
      <span v-text="itemLength"></span>
      <span>件</span>
    </div>
    <div class="order-price-check-item-list" v-if="orderData">
      <OrderPriceCheckItem v-for="(item, index) in filteredItemList" :key="index" :itemData="item" :priceDisplayType="orderData.event.priceDisplayType" :hide-edit-btn="true" @updateConfirm="updateConfirm" />
    </div>

    <CustomModalFooter class="form-footer d-flex justify-content-between align-items-center flex-nowrap">
      <b-button variant="primary" block :disabled="showConfirmBtn" @click="checkConfirm">
        <span>{{ isNeedBargain ? '確認報 (議) 價' : '確認報價' }}</span>
      </b-button>
    </CustomModalFooter>

    <transition name="fade">
      <FullScreenModal v-if="showConfirmAgreeConsign" title="委託約定" @back="closeConfirmAgreeConsignModal">
        <div>待請律師確認，請留文字編輯</div>
      </FullScreenModal>
    </transition>

    <SemiOverlayModal v-model="showConfirmBargain">
      <div class="bargain-settings">
        <h2 class="fs-h2 text-center">店家確認議價中</h2>
        <div class="desc">
          <span>已收到欲重新議價的單品最低回饋金額，店家將於7日內回覆確認。</span>
        </div>
        <b-button class="bargain-btn fs-16px" variant="primary" @click="confirmBargain">
          <span>確認</span>
        </b-button>
        <b-button class="bargain-btn fs-16px" variant="outline-primary" @click="closeConfirmModal">
          <span>取消</span>
        </b-button>
      </div>
    </SemiOverlayModal>

    <SemiOverlayModal v-model="showConfirmPrice">
      <div class="bargain-settings">
        <h2 class="fs-h2 text-center">確認報價及委託寄售</h2>
        <div class="desc">
          <span>單品上架後可能會依據販售時間或配合銷售活動優惠調整定價，實際回饋金將以最終售出定價計算。</span>
          <br>
          <span>寄件時請勿寄送不在本次申請之單品，如收到實品與照片不符合將不會上架。</span>
          <span class="warning-text">{{ orderData.event.type === 0 ? '未上架的單品將加收運費寄回。' : '未上架的單品將代捐贈給合作機構。'}}</span>
          <br>
          <span>確認委託 </span>
          <span v-text="itemLength"></span>
          <span> 件衣物寄售嗎？</span>
        </div>
        <b-button class="bargain-btn fs-16px" variant="primary" @click="confirmPrice">
          <span>確認</span>
        </b-button>
        <b-button class="bargain-btn fs-16px" variant="outline-primary" @click="closeConfirmModal">
          <span>取消</span>
        </b-button>
      </div>
    </SemiOverlayModal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import commonBusyAndErrorHandling from "@/assets/js/commonBusyAndErrorHandling";
import OrderPriceCheckItem from "@/views/share/OrderPriceCheckItem.vue";
import CustomModalFooter from "@/components/CustomModalFooter.vue";
import FullScreenModal from "@/components/FullScreenModal.vue";
import SemiOverlayModal from "@/components/SemiOverlayModal.vue";

export default {
  mixins: [commonBusyAndErrorHandling],
  name: 'HistoryOrderConfirmPriceCheck',
  data() {
    return {
      readingBusyName: 'HistoryOrderConfirmPriceCheck',

      currentTab: 'pending',

      agreeConsign: false,
      showConfirmAgreeConsign: false,
      contractorID: '',

      showConfirmBargain: false,
      showConfirmPrice: false,

      init: false,
    };
  },
  props: {
    orderData: {
      type: Object,
      required: true,
    },
  },
  components: {
    SemiOverlayModal,
    FullScreenModal,
    OrderPriceCheckItem,
    CustomModalFooter,
  },
  computed: {
    showConfirmBtn() {
      if(this.orderData) {
        return this.isNeedBargain ? this.orderData.cloths.filter(item => item.confirmStatus === 0).length !== 0 : !( this.agreeConsign && this.isValidContractorID );
      }
      return true;
    },
    filteredItemList() {
      if(this.orderData) {
        return this.orderData.cloths.filter((item) => {
          // !(item.confirmStatus === 1 && item.passStatus === 2)
          return item.passStatus === 1 && (item.confirmStatus === 1 || (item.confirmStatus === 2 && item.needBargain));
        });
      } else {
        return [];
      }
    },
    itemLength() {
      return this.filteredItemList.length;
    },
    isNeedBargain() {
      if (!this.orderData || !this.orderData.cloths || !this.orderData.cloths.length) {
        return false;
      }
      return this.orderData.cloths.some(cloth => cloth.needBargain === true);
    },
    isValidContractorID() {
      const id = this.contractorID;
      const regex = /^[A-Z][1-2]\d{8}$/;
      return regex.test(id)
    }
  },
  watch: {
  },
  async mounted() {
  },
  methods: {
    ...mapActions('event', ['refreshHistoryList', 'clearHistoryListData']),

    async updateConfirm() {
      console.log('updateConfirm')
      await this.handleAsyncTask(this.readInfo);
    },
    checkConfirm() {
      if(this.isNeedBargain) {
        this.showConfirmBargain = true;
      } else {
        this.showConfirmPrice = true;
      }
    },
    confirmBargain() {
      this.$emit('bargain')
    },
    confirmPrice() {
      this.$emit('confirmPrice', this.contractorID)
    },
    // async sendBargain() {
    //   await this.handleAsyncTask(async () => {
    //     await this.$store.dispatch('api/postReviewOrderSendBargainPromise', this.orderData.serial);
    //   });
    //   this.$emit('close')
    // },
    openConfirmAgreeConsignModal() {
      this.showConfirmAgreeConsign = true;
    },
    closeConfirmAgreeConsignModal() {
      this.showConfirmAgreeConsign = false;
    },
    closeConfirmModal() {
      this.showConfirmBargain = false;
      this.showConfirmPrice = false;
    },
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/basic";
.history-order-price-check {
  padding-bottom: 5rem;
  .price-check-desc {
    color: $typography-primary-default;
    padding: 1rem;
  }
  .price-check-info {
    padding: 0 1rem;
  }
  .price-check-count {
    padding: 0 1rem;
    &.fs-h3 {
      margin-bottom: 0;
    }
  }
  .order-price-check-item-list {
    padding-top: 1rem;
  }
  .info {
    .fs-h3 {
      margin-bottom: 0;
    }
  }
  .agree-consign {
    margin-bottom: 1rem;
  }
  .agree-consign-link {
    cursor: pointer;
    color: $typography-text-link;
  }
  .bargain-settings {
    .fs-h2 {
      margin-bottom: .75rem;
    }
    .desc {
      margin-bottom: .75rem;
    }
    .bargain-btn {
      width: 100%;
      &:not(:last-child) {
        margin-bottom: .75rem;
      }
    }
    .warning-text {
      color: $typography-primary-error;
    }
  }
}
</style>
<style lang="scss">
@import "src/assets/scss/basic";
.history-order-price-check {
  .order-price-check-item-list {
    .order-price-check-item {
      &:not(:last-child) {
        border-bottom: 1px solid $color-border;
      }
    }
  }
  .agree-consign {
    .custom-control  {
      font-size: 1rem;
    }
    .custom-control-label::before {
      top: 2px;
      left: -2rem;
      width: 1.25rem;
      height: 1.25rem;
      border: 2px solid $typography-secondary-default;
    }
    .custom-control-label::after {
      top: 2px;
      left: -2rem;
      width: 1.25rem;
      height: 1.25rem;
      background: 50% / 1rem 1rem no-repeat;
    }
  }
  .form-group {
    .col-form-label {
      padding-bottom: .75rem;
    }
    input.form-control {
      padding: 1rem;
    }
    .form-control:disabled, .form-control[readonly] {
      background-color: $color-btn-disabled-secondary;
      opacity: 1;
    }
  }
  .bargain-settings {
    .btn-outline-primary {
      border: 1px solid transparent;
    }
    .btn-outline-primary:not(:disabled):hover, .btn-outline-primary:focus, .btn-outline-primary:not(:disabled):not(.disabled):active {
      border: 1px solid transparent;
    }
  }
}
</style>
  